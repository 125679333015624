import img from "../../images/other_services/pexels-lisa-1453499_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import img1 from "../../images/other_services/pexels-km-l-3999647_.jpg";
import img2 from "../../images/other_services/pexels-skitterphoto-589_.jpg";
import FloatButton from "../../components/FloatButton";
// data


// data
const images = [
  {
    'title':'cesto',
    'img':img1,
  },{
    'title':'cesto',
    'img':img2,
  }
];
// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Giardinaggio " prepageName="Servizio" postpageName="Giardinaggio e Cura del Verde"/>
      <Service
        title="GIARDINAGGIO"
        text="Tenere il giardino pulito richiede tempo e pazienza. 
        Con Manutenpul avrai:"
        img={img}
        images={images}
      >
        <lu>
          <li>Manutenzione giardini</li>
          <li>Manutenzione giardini privati</li>
          <li>Manutenzione giardini pubblici</li>
          <li>Manutenzione giardini condominiali</li>
          <li>Manutenzione giardini milano e non solo</li>
          <li>Potatura piante</li>
          <li>Potatura piante da frutto</li>
          <li>Installazione e manutenzione impianti di irrigazione</li>
        </lu>
        <br />
        <br />
        <p>
        Manutenzione giardini:
        Per la manutenzione dei giardini, Manupenpul offre: 
        La potatura di cespugli e di siepi, la rasatura dei prati, la concimazione e la sarchiatura di aiuole.
        Lavori di manutenzione straordinaria quali: abbattimento di alberi instabili, potatura di piante                                                          ed alberi  ad alto fusto, difficilmente raggiungibili con strumenti comuni e tanti altri servizi ancora.
        Utilizzo di prodotti chimici conformi alla legge in vigore 
        Utilizzo di attrezzature specifiche</p>
        <br />
        <br />
        Potatura piante:
        La potatura delle piante è importante per favorire la ricrescita. 
        Con Manutenpul potrai avere:
        <lu>
            <li>Sfalcio dell'erba</li>
            <li>Realizzazione di sistemi di irrigazione automatizzati</li>
            <li>Taglio di siepi e potatura di alberi anche ad alto fusto e da frutto</li>
            <li>Trattamenti antiparassitari mirati ed efficaci</li>
            <li>Utilizzo di diserbanti non pericolosi per l’uomo e per gli animali</li>
            <li>Messa a dimora di piante, alberi, siepi e fiori di ogni genere</li>
        </lu>
        <br />
        <br />
        
        <p>Installazione e manutenzione impianti di irrigazione </p>
        <br />
        <p>L’irrigazione e il giusto impianto sono elementi fondamentali per la vita delle piante e per garantire il giusto drenaggio.</p>
        <br />
        <p>La giusta irrigazione garantisce un buon manto dall’erba, alle piante, ai fiori.</p>
        <br />
        <p>L’impianto e la manutenzione giardini privati e pubblici sono la garanzia del tuo investimento.</p>

      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
